import React, { useContext, useEffect, useState } from 'react';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import { VideoModal } from 'components/video-modal';
import { ResourceContentBlock } from 'components/resource-content-block';
import { CommonContainer } from 'components/common-container';

import './videos.scss';
import { StaticImage } from 'gatsby-plugin-image';

const pageTitle = 'HCP Resources';
const pageDescription = '';

const trackingPrescription = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'HCP Resources Direct Prescription form button',
    'HCP Resources Direct Prescription form ',
  );
};
const trackingHCPPI = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'HCP Resources Full Prescribing button',
    'HCP Resources HCP Full Prescribing',
  );
};

const trackingHCPCoreAid = () => {
  window.ga(
    'send',
    'event',
    'navigation',
    'HCP Resources Core Aid button',
    'HCP Resources Core Aid ',
  );
};

const VideosPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleClick = (url, e) => {
    e.persist();
    setSelectedVideoUrl(url);
    setShowModal(true);
  };

  const handleClose = () => {
    setSelectedVideoUrl('');
    setShowModal(false);
  };

  const andrewContent = () => (
    <>
      <p>
        Dr. Blumenfeld discusses expanding the pain management toolbox for
        neurologists
      </p>
      <ul>
        <li>
          Discusses the burden of headache and the central role of underlying
          inflammation
        </li>
        <li>
          Evaluates the efficacy and safety data of an NSAID for the short-term
          (up to 5 days) management of moderate to moderately severe acute pain
          in adult patients
        </li>
      </ul>
    </>
  );

  const neilJollyContent = () => (
    <>
      <p>
        Dr. Jolly discusses expanding the pain management toolbox with an
        opioid-free choice{' '}
      </p>
      <ul>
        <li>
          Presents various scenarios and patient types requiring acute pain
          management
        </li>
        <li>
          Evaluates the efficacy and safety data of an NSAID for the short-term
          (up to 5 days) management of moderate to moderately severe acute pain
          in adult patients
        </li>
      </ul>
    </>
  );

  return (
    isLoaded && (
      <Layout indication={siteIndications.hcp} className="hcp-videos">
        <Seo indication={siteIndications.hcp} pageTitle={pageTitle} />
        <CommonContainer>
          <h2 className="no-padding-bottom">
            <strong>VIDEOS</strong>
          </h2>
          <div className="video-container">
            <ResourceContentBlock
              left={
                <StaticImage
                  onClick={e =>
                    handleClick('https://player.vimeo.com/video/285867556', e)
                  }
                  src="../images/hcp/pages/videos/videos-andrew-thumb.png"
                  alt="Andrew video thumbnail"
                  placeholder="none"
                />
              }
              right={<>{andrewContent()}</>}
            />
          </div>
          <div className="standard-top-spacing"></div>
          <div className="video-container">
            <ResourceContentBlock
              left={
                <StaticImage
                  onClick={e =>
                    handleClick('https://player.vimeo.com/video/286361305', e)
                  }
                  src="../images/hcp/pages/videos/videos-neil-thumb.png"
                  alt="Neil video thumbnail"
                  placeholder="none"
                />
              }
              right={<>{neilJollyContent()}</>}
              classes="light-blue"
            />
          </div>

          {showModal && selectedVideoUrl && (
            <VideoModal
              closeModal={handleClose}
              open={showModal}
              url={selectedVideoUrl}
            />
          )}
        </CommonContainer>
      </Layout>
    )
  );
};

export default VideosPage;
