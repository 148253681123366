import React from 'react';
import ContentBlock from 'components/content-block';

import './index.scss';

export const ResourceContentBlock = props => {
  const classes = props.classes
    ? `resource-content-block-container ${props.classes}`
    : 'resource-content-block-container';

  const renderLeftBlock = () => {
    return <div className="left-content-block">{props.left}</div>;
  };

  const renderRightBlock = () => {
    return (
      <div className="right-content-block">
        <div className="content-block-text-container">{props.right}</div>
      </div>
    );
  };

  const leftBlock = props.left ? renderLeftBlock() : <></>;
  const rightBlock = props.right ? renderRightBlock() : <></>;

  return (
    <div className={classes}>
      <div className="resource-inner-content-block-container">
        <ContentBlock>
          <div className="video-container-section">
            {leftBlock}
            {rightBlock}
          </div>
        </ContentBlock>
      </div>
    </div>
  );
};
